<template>
  <div class="charts">
    <van-cell class="number"> 答题人数: {{ useTotal != null ? useTotal + '人' : '' }}</van-cell>
    <div v-for="(item, index) in list" :key="index">
      <div>
        <van-cell class="cell">
          <div class="title">{{ index + 1 }}.{{ item.content }} ({{ item.typeStr }})</div>
          <div class="cake">
            <div :id="'cake' + index" class="chart-box" v-if="item.type == 1"></div>
            <div :id="'column' + index" class="chart-box" v-if="item.type == 2"></div>
            <van-button @click="checkHandle" v-if="item.type == 0">查看详细信息</van-button>
          </div>
        </van-cell>
      </div>
    </div>
    <answer-info v-if="answerInfoVisible" ref="answerInfo"></answer-info>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import answerInfo from './answer-info'
import * as echarts from 'echarts'
import qrCode from '../visit/code'
import QRCode from "qrcodejs2";
import {getImageStream} from "@/utils/index";

export default {
  props: ['useTotal'],
  components: {
    answerInfo,
    qrCode
  },
  data() {
    return {
      single: [],
      list: [],
      id: 0,
      chartLine: null,
      columnLine: null,
      answerInfoVisible: false
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getInfo()
  },
  activated() {
    // 由于给echart添加了resize事件, 在组件激活时需要重新resize绘画一次, 否则出现空白bug
    if (this.chartLine || this.columnLine) {
      this.chartLine.resize()
      this.columnLine.resize()
    }
  },
  methods: {
    checkHandle() {
      this.answerInfoVisible = true
      let id = this.id
      this.$nextTick(() => {
        this.$refs.answerInfo.init(id)
      })
    },
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/questionnaire/statistics'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.id,
          orgId: this.$orgId
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          this.list = data.list
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    resize(val) {
      let nowClientWidth = document.documentElement.clientWidth
      return val * (nowClientWidth / 1920)
    },
    initChart() {
      // debugger
      this.$http({
        url: this.$http.adornUrl('/wxapp/questionnaire/statistics'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.id,
          orgId: this.$orgId
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          data.list.map((item, index) => {
            if (item.type === '1') {
              let cakes = 'cake' + index
              this.chartLine = echarts.init(document.getElementById(cakes))
              var option = {
                tooltip: {
                  trigger: 'item',
                  formatter: '{b}: {c} ({d}%)'
                },
                legend: {
                  icon: 'rect',
                  orient: 'horizontal',
                  type: 'scroll',
                  top: '86%',
                  itemWidth: this.resize(50),
                  itemHeight: this.resize(50),
                  // itemGap: this.resize(30),
                  bottom: 'bottom',
                  textStyle: {
                    fontSize: 14,
                  },
                  color: function (params) {
                    var colorList = ['#01C4FF', '#2A68DF', '#00BBAC', '#8546EE', '#656BF6', '#EF4864', '#FACC14',
                      '#2FC25B', '#B620E0', '#6236FF', '#F69F65', '#FEFEFE']
                    return colorList[params.dataIndex]
                  },
                  itemStyle: {
                    borderCap: 'square',
                  }
                },
                series: [
                  {
                    // name: item[i].op,
                    type: 'pie',
                    radius: '50%',
                    data: item.statistics,
                    itemStyle: {
                      normal: {
                        color: function (params) {
                          var colorList = ['#01C4FF', '#2A68DF', '#00BBAC', '#8546EE', '#656BF6', '#EF4864', '#FACC14',
                            '#2FC25B', '#B620E0', '#6236FF', '#F69F65', '#FEFEFE']
                          return colorList[params.dataIndex]
                        }
                      }
                    },
                    label: {
                      label: {
                        show: true,
                        formatter: '{b}:{c}({d}%)'
                      },
                      labelLine: {show: true},
                      // }
                    },
                    emphasis: {
                      itemStyle: {
                        fontSize: this.resize(34),
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                    },
                    labelLine: { // 数据引导线
                      show: true,
                      length: this.resize(80),
                      length2: this.resize(40),
                      smooth: true
                    },
                  }
                ]
              }
              this.chartLine.setOption(option)
            } else if (item.type === '2') {
              var that = this
              let columns = 'column' + index
              that.columnLine = echarts.init(document.getElementById(columns))
              let xData = []
              let yData = []
              item.statistics.map(ele => {
                xData = ele.xdata
                yData = ele.ydata
              })
              var options = {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  },
                  formatter: '{b}:{c}'
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: {
                  type: 'value',
                  boundaryGap: [0, 0.1],
                  min: 0,
                  max: 40,
                  splitNumber: 5,
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: ['#c1d0e0']
                    }
                  },
                  axisLabel: {
                    textStyle: {
                      color: '#000'
                    }
                  }
                },
                yAxis: {
                  type: 'category',
                  splitNumber: 5,
                  data: yData,
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: ['#c1d0e0']
                    }
                  },
                  axisLabel: {
                    textStyle: {
                      color: '#000'
                    }
                  }
                },
                series: [
                  {
                    // name: this.multipleList,
                    type: 'bar',
                    data: xData,
                    itemStyle: {
                      normal: {
                        color: function (params) {
                          var colorList = ['#01C4FF', '#2A68DF', '#00BBAC', '#8546EE', '#656BF6', '#EF4864', '#FACC14',
                            '#2FC25B', '#B620E0', '#6236FF', '#F69F65', '#FEFEFE']
                          return colorList[params.dataIndex]
                        },
                        label: {
                          show: true,  //开启显示
                          formatter: '{c}',
                          position: 'right',
                          textStyle: {  //数值样式
                            color: 'black',
                            fontSize: that.resize(60)
                          },
                        }
                      }
                    }
                  },
                ]
              }
              that.columnLine.setOption(options)
            }
          })
          window.addEventListener('resize', () => {
            if (this.chartLine) {
              this.chartLine.resize()
              this.columnLine.resize()
            }
          })
        } else {
          this.$toast.fail(data.msg);
        }
      })

    }
  }
}
</script>
<style lang="scss" scoped>
.charts {
  height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.cake {
  position: relative;
  width: 100%;
  height: 28vh;

  .chart-box {
    height: 100%;
    z-index: 999;
    margin-right: 50px;
  }
}

.column {
  position: relative;
  width: 100%;
  height: 24vh;

  .chart-box {
    height: 100%;
    z-index: 999;
    margin-right: 50px;
  }
}

.cell {
  .van-button {
    height: 70px;
    color: #367ff3;
    border: 1px solid #397ff3;
    background-color: #ebf2fd;
    border-radius: 10px;
    margin-top: 20px;
  }
}

.number {
  padding-left: 20px;
}

#qrCodeUrl {
  display: inline-block;
  margin-left: 190px;
  margin-top: 340px;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    //width: 100px;
    //height: 100px;
    background-color: #fff; //设置白色背景色
    //padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
    //margin-left: 50%;
  }
}

.write {
  position: absolute;
  left: 18%;
  top: 270px;
  font-size: 30px;
  color: #00a2d3;
}

.van-image {
  position: absolute;
  left: 25%;
  top: 22%;
  //width: 100px;
  //height: 100px;
  background-color: #fff; //设置白色背景色
  //padding: 6px; // 利用padding的特性，挤出白边
  box-sizing: border-box;
  //margin-left: 50%;
}
</style>
